import React, { Component } from 'react';
import './Home.css';

export class Home extends Component {
    static displayName = Home.name;

    render() {
        return (
            <div className="gRow gWidth100 gCenteredContent">
                <div className="gCollumn gCenteredHeight ldvHomeContainer">
                    <div className="gRow ldvHomeTitle">
                        Строим самую большую в мире карту опыта решений
                    </div>
                    <div className="gCollumn gWidth100 ldvPointAContainer">
                        <div className="gRow gWidth100 gCenteredWidth">
                            <img src="/images/mainpic.svg" alt="start" className="limgStartPic"></img>
                        </div>
                        <div className="gRow gWidth100 gCenteredWidth">
                            <div className="gRow ldvPointA">
                                <div className="gCollumn gHeight100 ldvPointATextContainer">
                                    <div className="gRow ldvPointATitle">
                                        Вы находитесь<br /> в точке А
                                    </div>
                                    <div className="gRow ldvPointAText">
                                        Начните изучать информацию
                                    </div>
                                </div>
                                <div className="gRow gHeight100 gCenteredHeight ldvPointAImg">
                                    <img src="/images/point-a.svg" alt="pointA" className="limgPointA"></img>
                                </div>
                            </div>
                        </div>
                        <div className="gRow gWidth100 gCenteredWidth">
                            <div className="gRow ldvPointADescription">
                                Open Experience — некоммерческий проект
                                по созданию силами участников сообщества
                                подробной, свободной и бесплатной карты
                                опыта решений в логистическом формате.
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow ldvSectionLeft ldvExperience">
                            <div className="gCollumn ldvSectionContent">
                                <div className="gRow gWidth100 ldvSectionTitle">
                                    Накопленный<br />
                                    опыт решений
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Open Experience является картой опыта решений, созданной такими же людьми, как и вы, и может свободно использоваться под открытой лицензией.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Open Experience создан сообществом энтузиастов, лингвистов, инженеров, программистов, которые добавляют и поддерживают информацию о смыслах и опыте, создавая верифицированную базу данных.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow ldvSectionRight ldvInfluence">
                            <div className="gCollumn ldvSectionContent">
                                <div className="gRow gWidth100 ldvSectionTitle">
                                    Моделирование
                                    влияния
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Сервис моделирования влияния законов, указов, распоряжений, технических заданий, договоров, на компании, общество, города, страны.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Моделирование влияния даёт новые возможности по выработке оптимальных управленческих решений, повышения производительности организационно-технических систем.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredContent ldvMiddle">
                        <div className="gRow gCenteredContent ldvMiddleContent">
                            Вы в середине пути!
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow ldvSectionLeft ldvEfficiency">
                            <div className="gCollumn ldvSectionContent">
                                <div className="gRow gWidth100 ldvSectionTitle">
                                    Мониторинг
                                    эффективности
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Сервис на базе данных Open Experience позволяет вести мониторинг эффективности выполнения поручений, как в частном, коммерческом, так
                                    и государственном секторах.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Сквозная аналитика в реальном времени для построения ситуационного центра
                                    на реальных первичных данных.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow ldvSectionRight ldvUse">
                            <div className="gCollumn ldvSectionContent">
                                <div className="gRow gWidth100 ldvSectionTitle">
                                    Сферы<br/>
                                    применения
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Государственное управление, умные города, строительство, промышленность, информационные
                                    и юридические системы.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Поиск узких мест, оптимизация процессов, оценка производительности, сокращение затрат, получение полноты картины влияния.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow ldvSectionLeft ldvSemantic">
                            <div className="gCollumn ldvSectionContent">
                                <div className="gRow gWidth100 ldvSectionTitle">
                                    Семантическое
                                    представление
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Опыт описывается на естественном языке
                                    в логистическом формате хранения информации.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Удобный и всем понятный формат представления информации не требующий знания языков программирования или юридического образования.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow ldvSectionRight ldvLogistic">
                            <div className="gCollumn ldvSectionContent">
                                <div className="gRow gWidth100 ldvSectionTitle">
                                    Логистический
                                    формат
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    ABW — это микропрограмма строится по<br/> конструкции: точка А, точка Б, путь.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Опыт — это совокупность ABW, формирующая карту решений.
                                </div>
                                <div className="gRow gWidth100 ldvSectionText">
                                    Point A —  вы, презентация.<br/>
                                    Point B — узнать, что такое Open Experience.<br/>
                                    Way — получение информации.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gCollumn gWidth100 ldvPointBContainer">
                        <div className="gRow gWidth100 gCenteredWidth">
                            <div className="gRow ldvPointB">
                                <div className="gCollumn gHeight100 ldvPointBTextContainer">
                                    <div className="gRow ldvPointBTitle">
                                        Поздравляем,<br/>
                                        вы достигли цели!
                                    </div>
                                    <div className="gRow ldvPointBText">
                                        Мы поставили цель — дать вам информацию
                                        о проекте и создали микропрограмму в виде этой презентации.
                                    </div>
                                    <div className="gRow ldvPointBText">
                                        В самом начале вы были в точке А и не знали, что такое Open Experience, вы прошли путь и сняли часть неопределенности, получив информацию.
                                    </div>
                                </div>
                                <div className="gRow gHeight100 gCenteredHeight ldvPointBImg">
                                    <img src="/images/point-b.svg" alt="pointB" className="limgPointB"></img>
                                </div>
                            </div>
                        </div>
                        <div className="gRow gWidth100 gCenteredWidth">
                            <div className="gCollumn ldvPointBDescription">
                                <div className="gRow gWidth100 gCenteredWidth">
                                    <div className="gRow ldvPointBDescriptionTitle">
                                        Что дальше?
                                    </div>
                                </div>
                                <div className="gRow gWidth100 gCenteredWidth">
                                    <div className="gRow ldvPointBDescriptionText">
                                        Зарегистрируйтесь на сайте и получите больше информации об Open Experience.
                                    </div>
                                </div>
                                <div className="gRow gWidth100 gCenteredWidth">
                                    <div className="gRow gCenteredContent ldvPointBDescriptionBtn">
                                        Регистрация →
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gRow gWidth100 gCenteredWidth">
                        <div className="gRow gCenteredContent ldvFooter">
                            2022 © Open Experience
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './NavMenu.css';

export class NavMenu extends Component {
    static displayName = NavMenu.name;

    render() {
        return (
            <header>
                <div className="gRow gWidth100 gCenteredContent" >
                    <div className="gRow gCenteredHeight ldvNavMenuContainer">
                        <div tag={Link} to="/"><img className="ldvNavLogo" alt="logo" src="/images/logo.svg"></img></div>
                        <div className="gRow ldvNavItemsContainer">
                            <div className="gRow ldvNavItem" tag={Link} to="/">О проекте</div>
                            <div className="gRow ldvNavItem" tag={Link} to="/">Авторские права</div>
                            <div className="gRow ldvNavItem" tag={Link} to="/">Инвесторам</div>
                        </div>
                        <div className="gRow gCenteredContent ldvLangContainer">
                            <img className="gRow ldvNavLangImg" alt="lang" src="/images/lang.svg"></img>
                            <div className="gRow ldvNavLangTitle" >EN</div>
                        </div>
                        <div className="gRow gCenteredContent ldvLoginContainer">
                            <img className="gRow ldvNavLoginImg" alt="login" src="/images/login.svg"></img>
                            <div className="gRow ldvNavLoginTitle" >Вход</div>
                        </div>
                        <div className="gRow gCenteredContent ldvRegBtn">
                            Регистрация
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}
